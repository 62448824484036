import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import SyncConstants from "../../constants/SyncConstants";
import { copy, getSlotDate } from "../../libs/SlotUtils";
import ExpenseEntryList from "./ExpenseEntryList";
import stylelist from "stylelist";
import { set_sync_status } from "../../actions/Sync";
import NewProject from "./NewProject";
import { getFriday, getMonday } from "../../libs/CalendarWeek";
import MinutesToTime from "./MinutesToTime";
import { set_expense_project_root_id } from "../../actions/User";
import ExpenseMask from "../Slots/ExpenseMask";
import useAuthentication from "../../hooks/useAuthentication";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { generateRandomClassName } from "../../libs/StringUtil";
import MonthlyBreakdown from "./MonthlyBreakdown";

const ExpenseProject = (props) => {

    const UserSettings = useSelector((state) => state.User);
    const dispatch = useDispatch();
    const Team = useSelector((state) => state.Team);
    const Auth = useAuthentication();

    // All server data (Expense Project Data)
    const [serverData, setServerData] = useState({});

    // Toggles if child projects are shown (Expanded)
    const [showChildren, setShowChildren] = useState(false);

    // Toggles if booked expense entries are shown (recursive for all sub-projects)
    const [showExpenseEntries, setShowExpenseEntries] = useState(false);

    const [showProjectBreakdown, setShowProjectBreakdown] = useState(false);

    // Filter-value for "Date from"
    const [filterDateFrom, setFilterDateFrom] = useState(props.filterDateFrom);

    // Filter-value for "Date to"
    const [filterDateTo, setFilterDateTo] = useState(props.filterDateTo);

    const params = useParams();

    // Filter-value for "Person"
    const [filterPerson, setFilterPerson] = useState(props.filterDatePerson);

    const [projectEditMode, setProjectEditMode] = useState(false);

    const [separateByDays, setSeparateByDays] = useState(false);

    const [showNewProject, setShowNewProject] = useState(false);

    const [progressMinutes, setProgressMinutes] = useState(null);

    const [showEditProjectId, setShowEditProjectId] = useState(false);

    const [expandView, setExpandView] = useState(false);

    const [loading, setLoading] = useState(false);

    const [expenseMaskId, setExpenseMaskId] = useState(0);

    const [searchword, setSearchword] = useState("");

    const [autoExpandUsed, setAutoExpandUsed] = useState(false);

    const [openThisId, setOpenThisId] = useState(false);
    const [openChildId, setOpenChildId] = useState(false);

    const randomClassName = generateRandomClassName();

    const [currentPath, setCurrentPath] = useState("");


    // Run after initializing
    const init = useEffect(() => {
        // Load all child projects from the server
        loadFromServer();

        // Dispatch a global sync (to make sure we have team members etc.)
        dispatch(set_sync_status("asap_full"));

        // Show children if we are on level 1 or 2, so user dont have to expand it manually
        if (props.level == 1) {
            setShowChildren(true);
        }

        if (params.id !== undefined) {
            let parts;

            if (props.level == 1) {
                parts = params.id.split("-");
                setCurrentPath(1 + "-");
            } else {
                parts = props.remainingUrlPath.split("-");
            }

            const first = parts[0];
            const rest = parts.slice(1).join("-");

            setOpenThisId(first);
            setOpenChildId(rest);

            if (props.expenseProjectId == first) {
                setShowChildren(true);
            }

            if (rest == false && props.expenseProjectId == first) {
                setShowExpenseEntries(true);
            }
        }

        let currentPath = props.parentPath + "-" + props.expenseProjectId;

        // Entferne den ersten Bindestrich, falls vorhanden
        if (currentPath.startsWith("-")) {
            currentPath = currentPath.substring(1);
        }

        setCurrentPath(currentPath);
    }, []);

    const reforce = useEffect(() => {
        loadFromServer();
    }, [props.expenseProjectId]);

    useEffect(() => {
        if (props.level > 0) {
            setProjectEditMode(props.projectEditMode);
            setSeparateByDays(props.separateByDays);
        }
    }, [props.projectEditMode, props.separateByDays]);

    const updateProgressMinutes = () => {
        axios({
            method: "get",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntries/sum",
            params: {
                project_id: props.expenseProjectId
            },
            responseType: 'json'
        }).then((response) => {

            let total = response.data.calculation.total;
            if (total > 0) {
                setProgressMinutes(total);
                setProgressPercentage((100 / serverData.parent[0].maxminutes * response.data.calculation.total));
            } else {
                setProgressMinutes(0);
                setProgressPercentage(0);
            }

        }).catch(response => { });
    }

    const filterToCurrentWeek = () => {
        setFilterDateFrom(getSlotDate(getMonday(new Date()).toDate()));
        setFilterDateTo(getSlotDate(getFriday(new Date()).toDate()));
    }

    useEffect(e => {
        if (serverData.children !== undefined && serverData.parent !== undefined) {
            if (props.searchword && serverData.parent[0].title.toLowerCase().indexOf(props.searchword.toLowerCase()) > -1 && props.searchword.length >= 3) {
                setShowChildren(true);
                setAutoExpandUsed(true);
            }

            if (autoExpandUsed && props.searchword.length == 0) {
                setShowChildren(false);
                setAutoExpandUsed(false);
            }
        }


    }, [props.searchword, searchword]);

    // Set filter-values to inherited props
    useEffect(e => {
        setFilterDateFrom(props.filterDateFrom);
        setFilterDateTo(props.filterDateTo);
        setFilterPerson(props.filterPerson);
    }, [props.filterDateFrom, props.filterDateTo, props.filterPerson])

    // Get all expenseProjects by this ID from the Server
    // @todo: Add error handling and so on
    const loadFromServer = () => {
        setLoading(true);
        axios({
            method: "get",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseProjects/byParent",
            params: {
                parent: props.expenseProjectId
            },
            responseType: 'json'
        }).then((response) => {
            setServerData(response.data);
            updateProgressMinutes();
            setLoading(false);
        }).catch(response => { });
    }

    return <>

        {/* Start: The first level of our tree. We will render some global controls here */}
        {(props.level == 1 && !props.selectMode) &&
            <>
                <div className="d-flex justify-content-between">
                    <h1>Arbeitsaufwände</h1>
                </div>

                <div className="bg-light border ps-3 pe-3 pt-2 pb-2">

                    <div className="d-flex justify-content-between">
                        <div>
                            <h4 className="mt-3">Presets</h4>
                            <div className="filter container ms-0 ps-0">
                                <div className="row">
                                    {/* Start Filter: Date from */}
                                    <div className="mb-3 col ">
                                        <div className="btn btn-secondary btn-sm me-1" onClick={() => { setFilterPerson(UserSettings.userName) }}>Meine Aufwände</div>
                                        <div className="btn btn-secondary btn-sm me-1" onClick={filterToCurrentWeek}>Aktuelle KW</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>

                                <div className="form-check form-switch pt-3">
                                    <input className="form-check-input" type="checkbox" value={projectEditMode} onChange={e => { setProjectEditMode(e.target.checked) }} />
                                    <label className="form-check-label" for="">Projektstruktur editieren</label>
                                </div>

                                <div className="form-check form-switch pt-3">
                                    <input className="form-check-input" type="checkbox" value={props.expenseBucket} onChange={e => { props.setShowExpenseBucket(!props.expenseBucket) }} />
                                    <label className="form-check-label" for="">Abrechnungsmodus</label>
                                </div>

                                <div className="form-check form-switch pt-3">
                                    <input className="form-check-input" type="checkbox" value={separateByDays} onChange={e => { setSeparateByDays(e.target.checked) }} />
                                    <label className="form-check-label" for="">Nach Tagen separieren</label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <h4 className="mt-3">Filter</h4>
                    {/* Start: Filter by from- and to-date and the person */}
                    <div className="filter container ms-0 ps-0">

                        <div className="row">
                            <div className="col-6">

                                <label for="exampleFormControlInput1" className="form-label">Kunde</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={searchword} onChange={e => { setSearchword(e.target.value) }} />
                                    {searchword != "" &&
                                        <span className="input-group-text pt-1"><i onClick={() => { setSearchword("") }} className=" cursor-pointer bi bi-x-circle"></i></span>

                                    }
                                </div>
                            </div>

                        </div>

                        <div className="row mt-3">
                            {/* Start Filter: Date from */}
                            <div className="mb-3 col ">
                                <label for="exampleFormControlInput1" className="form-label">Datum (von)</label>
                                <input type="date" className="form-control" value={filterDateFrom} onChange={e => { setFilterDateFrom(e.target.value) }} />
                            </div>
                            {/* End Filter: Date from */}

                            {/* Start Filter: Date to */}
                            <div className="mb-3 col ">
                                <label for="exampleFormControlInput1" className="form-label">Datum (bis)</label>
                                <input type="date" className="form-control" value={filterDateTo} onChange={e => { setFilterDateTo(e.target.value) }} />
                            </div>
                            {/* End Filter: Date to */}

                            {/* Start Filter: Person */}
                            <div className="mb-3 col ">
                                <label for="exampleFormControlInput1" className="form-label">Person</label>
                                <select value={filterPerson} className="form-select" onChange={e => { setFilterPerson(e.target.value) }}>
                                    <option value="">Alle</option>

                                    {/* Start: Iterate through all team members who has slots */}
                                    {Object.values(Team).sort((a, b) => { return a.realName > b.realName }).map(m => {
                                        return <React.Fragment key={m.realName}>
                                            {(m.status == "active") &&
                                                <option value={m.userName} className="form-control" >{m.realName} </option>
                                            }
                                        </React.Fragment>
                                    })}
                                    {/* End: Iterate through all team members who has slots */}
                                </select>
                            </div>
                            {/* End Filter: Person */}

                        </div>
                    </div>

                </div>
                {/* End: Filter by from- and to-date and the person */}
                <br />

                {Boolean(UserSettings.currentExpenseProjectRootId != 1 && serverData.parent != undefined) &&
                    <>
                        <div className="bg-light border ps-3 pe-3 pt-2 pb-2 mb-4">
                            Projekt <b>{serverData.parent[0].title}</b> als Root-Knoten gesetzt.
                        </div>
                    </>
                }
            </>


        }
        {/* End: The first level of our tree. We will render some global controls here */}

        <div className={stylelist(
            "container pe-0 m-0 p-0 mt-2",
            [props.level === 1, "ms-0 mt-4 pt-5"],
        )}>
            <div className={stylelist(
                [props.level === 1, "ms-0 mt-4 pt-5"],
                [props.level > 1, "ms-3"]
            )}>
                {!loading &&
                    <>
                        {Boolean((serverData.children !== undefined && serverData.parent !== undefined) &&
                            (!props.searchword || serverData.parent[0].title.toLowerCase().indexOf(props.searchword.toLowerCase()) > -1)
                        ) &&
                            <>

                                <div className={stylelist("d-flex justify-content-between bg-on-hover pt-2", randomClassName)}>
                                    <h3 className={stylelist(
                                        [props.level == 1, "h6"],
                                        [props.level == 2, "h6"],
                                        [props.level == 3, "h6"],
                                        [props.level > 3, "h6"],
                                    )}>

                                        {/* Start: Collapse and Expand Tree */}
                                        {(showChildren && (serverData.children.length > 0)) &&
                                            <span className="text-primary cursor-pointer" onClick={e => { setShowChildren(false); }}><i className="bi bi-dash"></i></span>
                                        }

                                        {(!showChildren && (serverData.children.length > 0)) &&
                                            <span className="text-primary cursor-pointer" onClick={e => { setShowChildren(true); }}><i className="bi bi-plus"></i></span>
                                        }
                                        {/* End: Collapse and Expand Tree */}

                                        &nbsp;
                                        <span className={stylelist([serverData.children.length == 0, "ps-3"])}>

                                            {/* Start: Render title. When in select mode, we will call the given callback function.
                                            otherwise, we will just expand all children-items */}
                                            {(!props.selectMode) &&
                                                <>
                                                    {serverData.children.length > 0 &&
                                                        <>
                                                            <span onClick={e => { setShowChildren(!showChildren) }} className="cursor-pointer">{serverData.parent[0].title}</span>
                                                        </>
                                                    }

                                                    {serverData.children.length == 0 &&
                                                        <>
                                                            <span onClick={e => { setShowExpenseEntries(!showExpenseEntries) }} className="cursor-pointer">{serverData.parent[0].title}</span>
                                                        </>
                                                    }
                                                </>
                                            }

                                            {(props.selectMode) &&
                                                <>
                                                    {(serverData.parent[0].bookable == true) &&
                                                        <span onClick={e => { props.onSelect(serverData.parent[0]); }} className="cursor-pointer">{serverData.parent[0].title}</span>
                                                    }

                                                    {(serverData.parent[0].bookable == false) &&
                                                        <span className="text-muted cursor-na">{serverData.parent[0].title}</span>
                                                    }
                                                </>
                                            }
                                            {/* End: Render title */}

                                        </span>
                                        &nbsp;
                                    </h3>

                                    <div>
                                        <div className="row g-0">
                                            <div className="col-auto">
                                                {Boolean(serverData.parent[0].maxminutes > 0) &&
                                                    <>
                                                        <progress value={progressMinutes} className="pt-2" max={(serverData.parent[0].maxminutes)} />
                                                    </>
                                                }
                                            </div>

                                            <div className="col-auto">
                                                {!projectEditMode &&
                                                    <>

                                                        {/* Start: Offer to expand Expenses in Detail view */}
                                                        {(!showExpenseEntries && !showProjectBreakdown && !props.selectMode) > 0 &&
                                                            <>
                                                                {Boolean(UserSettings.currentExpenseProjectRootId == serverData.parent[0].id && serverData.parent[0].id != 1) &&
                                                                    <>
                                                                        <span title="Nicht mehr als Root-Knoten verwenden" className="text-primary cursor-pointer h6 me-2" onClick={e => { dispatch(set_expense_project_root_id(1)); window.scrollTo(0, 0) }}>&nbsp; <i className="bi bi-x-circle"></i></span>
                                                                    </>
                                                                }

                                                                {UserSettings.currentExpenseProjectRootId != serverData.parent[0].id &&
                                                                    <>
                                                                        <span title="Als Root-Knoten anzeigen" className="text-primary cursor-pointer h6 me-2" onClick={e => { dispatch(set_expense_project_root_id(serverData.parent[0].id)); window.scrollTo(0, 0) }}>&nbsp; <i className="bi bi-house"></i></span>
                                                                    </>
                                                                }

                                                                <span title="Aufwände anzeigen" className="text-primary cursor-pointer h6 me-2" onClick={e => { setShowExpenseEntries(!showExpenseEntries); setShowProjectBreakdown(false); setShowNewProject(false); }}>&nbsp; <i className="bi bi-view-list"></i></span>

                                                                {!showProjectBreakdown && UserSettings.betaEnabled &&
                                                                    <>
                                                                        <span className="text-primary cursor-pointer h6 me-2" title="Projekt Breakdown anzeigen" onClick={() => { setShowProjectBreakdown(true); setShowExpenseEntries(false); setShowNewProject(false); }}>
                                                                            &nbsp;   <i class="bi bi-calendar2"></i>
                                                                        </span>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {(showExpenseEntries && !props.selectMode) &&
                                                            <span className="text-primary cursor-pointer h6 me-2" onClick={e => setShowExpenseEntries(!showExpenseEntries)}>&nbsp; Schließen</span>
                                                        }

                                                        {(showProjectBreakdown && !props.selectMode) &&
                                                            <span className="text-primary cursor-pointer h6 me-2" onClick={e => setShowProjectBreakdown(!showProjectBreakdown)}>&nbsp; Schließen</span>
                                                        }
                                                        {/* End: Offer to expand Expenses in Detail view */}

                                                    </>
                                                }

                                                {projectEditMode &&
                                                    <>
                                                        {(!showNewProject && showEditProjectId < 1) &&
                                                            <>
                                                                <span className="text-primary cursor-pointer h6" onClick={e => setShowNewProject(true)}>&nbsp; Neu</span>
                                                                <span className="text-primary cursor-pointer h6" onClick={e => setShowEditProjectId(serverData.parent[0].id)}>&nbsp; Bearbeiten</span>
                                                            </>
                                                        }

                                                        {showEditProjectId > 0 &&
                                                            <>
                                                                <span className="text-primary cursor-pointer h6" onClick={e => setShowEditProjectId(false)}>&nbsp; Schließen</span>
                                                            </>
                                                        }

                                                        {showNewProject &&
                                                            <>
                                                                <span className="text-primary cursor-pointer h6" onClick={e => setShowNewProject(!showNewProject)}>&nbsp; Schließen</span>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {showEditProjectId > 0 &&
                                    <NewProject editId={showEditProjectId} afterSubmit={(needDeepRefresh) => { 
                                        loadFromServer(); 
                                        setShowEditProjectId(false); 
                                        props.parentLoadFromServer();
                                        if(needDeepRefresh) {props.parentLoadFromServer();}
                                    }} />
                                }

                                {(showNewProject && projectEditMode) &&
                                    <>
                                        <NewProject projectParentId={serverData.parent[0].id} afterSubmit={(needDeepRefresh) => { 
                                           loadFromServer();
                                            setShowNewProject(false);
                                            /*props.parentLoadFromServer();*/
                                        }} />
                                    </>
                                }

                                {showProjectBreakdown &&
                                    <>
                                        <div className="border mt-2 mb-2 pt-3 pb-3 bg-light p-3 ps-4 pe-4 entry-list">
                                            <MonthlyBreakdown project_id={serverData.parent[0].id} />
                                            <br />
                                        </div>
                                    </>
                                }

                                {showChildren && !showExpenseEntries &&
                                    <>

                                        {Object.values(serverData.children).filter(e => {console.log(e); return e.status == 1}).length == 0 && 
                                            <>
                                                <div className="alert alert-info me-3 mt-2 ms-3">
                                                    <b>Alle Unterprojekte sind archiviert.</b> Möglicherweise kann das Hauptprojekt auch archiviert werden.
                                                </div>
                                            </>
                                        }

                                        {/* Start: Show all child-elements */}
                                        {Object.values(serverData.children).filter(e => {console.log(e); return e.status == 1}).map(iteration => {
                                            return  <React.Fragment key={iteration.id}>
                                                <ExpenseProject
                                                    expenseProjectId={iteration.id}
                                                    level={parseInt(props.level) + 1}
                                                    filterDateFrom={filterDateFrom}
                                                    filterDateTo={filterDateTo}
                                                    filterPerson={filterPerson}
                                                    selectMode={props.selectMode}
                                                    onSelect={props.onSelect}
                                                    projectEditMode={projectEditMode}
                                                    separateByDays={separateByDays}
                                                    expenseBucket={props.expenseBucket}
                                                    searchword={searchword}
                                                    remainingUrlPath={openChildId}
                                                    parentPath={currentPath}
                                                    parentLoadFromServer={() => {loadFromServer()}}
                                                />

                                            </React.Fragment>
                                        })}
                                        {/* End: Show all child-elements */}

                                    </>
                                }



                                {/* Start: Show all Expense Entries ("Aufwände") to this project */}
                                {showExpenseEntries &&
                                    <>
                                        <div className={stylelist(
                                            "border mt-2 mb-2 pt-3 pb-3 bg-light p-3 ps-4 pe-4 entry-list",
                                            [expandView, "expand-absolute ps-5 pe-5"]
                                        )}>

                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h5 className="mt-3 mb-0">
                                                        {serverData.parent[0].title} inkl. Unterprojekte
                                                        {Boolean((serverData.parent[0].maxminutes) > 0) && (
                                                            <>
                                                                &nbsp;({((progressMinutes / serverData.parent[0].maxminutes) * 100).toFixed(2)}%)
                                                            </>
                                                        )}
                                                    </h5>
                                                    <h6 className="text-muted mb-4 mt-3">
                                                        <span className={stylelist(
                                                            [((progressMinutes / serverData.parent[0].maxminutes) * 100) > 100 && serverData.parent[0].maxminutes > 0, "text-danger"],
                                                            [((progressMinutes / serverData.parent[0].maxminutes) * 100) > 80 && serverData.parent[0].maxminutes > 0, "text-warning"],
                                                            [((progressMinutes / serverData.parent[0].maxminutes) * 100) <= 80 && serverData.parent[0].maxminutes > 0, "text-success"],
                                                        )}>
                                                            Aufwände: <b><MinutesToTime minutes={progressMinutes > 0 ? progressMinutes : 0} /></b>
                                                            {serverData.parent[0].maxminutes > 0 && (
                                                                <>
                                                                    &nbsp;von <b><MinutesToTime minutes={serverData.parent[0].maxminutes} /></b>
                                                                </>
                                                            )}
                                                        </span>
                                                    </h6>
                                                </div>


                                                <div>
                                                    <div class="btn-group mt-2" role="group" aria-label="Basic example">
                                                        <a title="Deeplink öffnen" href={"https://slotify.unternehmen.online/expenses/" + currentPath} className="btn btn-outline-secondary" target="_BLANK">
                                                            <i onClick={() => { setExpenseMaskId(0) }} className="cursor-pointer bi bi-link"></i>
                                                        </a>

                                                        {expenseMaskId > 0 &&
                                                            <>
                                                                <button title="Aufwandsliste schließen" onClick={() => { setExpenseMaskId(0) }} className="btn btn-secondary">
                                                                    <i className="cursor-pointer bi bi-x-lg"></i>
                                                                </button>

                                                            </>
                                                        }

                                                        {expenseMaskId == 0 &&
                                                            <>
                                                                <button title="Aufwandsliste öffnen" onClick={() => { setExpenseMaskId(serverData.parent[0].id) }} className="btn btn-outline-secondary">
                                                                    <i className="cursor-pointer bi bi-plus-lg"></i>
                                                                </button>
                                                            </>
                                                        }

                                                        {expandView &&
                                                            <>
                                                                <button title="Normale Breite nutzen" onClick={() => { setExpandView(false) }} className="btn btn-secondary">
                                                                    <i className="bi cursor-pointer bi-arrows-collapse-vertical"></i>
                                                                </button>
                                                            </>
                                                        }

                                                        {!expandView &&
                                                            <>
                                                                <button title="Volle Breite nutzen" onClick={() => { setExpandView(true) }} className="btn btn-outline-secondary">
                                                                    <i className="bi cursor-pointer bi-arrows-expand-vertical"></i>
                                                                </button>
                                                            </>
                                                        }


                                                    </div>
                                                </div>
                                            </div>


                                            <div>
                                                {expenseMaskId == serverData.parent[0].id &&
                                                    <div className="border bg-white p-3 pb-2 mb-5">
                                                        <ExpenseMask slotId="" afterSubmit={() => { setExpenseMaskId(0); loadFromServer(); }} forProject={serverData.parent[0]} hideDeleteButton={true} />
                                                    </div>
                                                }
                                            </div>

                                            <div>
                                                <ExpenseEntryList
                                                    project={serverData.parent[0].id}
                                                    filterDateFrom={filterDateFrom}
                                                    filterDateTo={filterDateTo}
                                                    filterPerson={filterPerson}
                                                    separateByDays={separateByDays}
                                                    expandView={expandView}
                                                    expenseBucket={props.expenseBucket}
                                                    parentPath={currentPath}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* End: Show all Expense Entries ("Aufwände") to this project */}
                            </>
                        }
                    </>}

                {loading &&
                    <>

                        <div className="d-flex justify-content-between bg-on-hover pt-2">
                            <h3 className={stylelist(
                                [props.level == 1, "h6"],
                                [props.level == 2, "h6"],
                                [props.level == 3, "h6"],
                                [props.level > 3, "h6"],
                            )}>

                                <span className="text-primary cursor-pointer" onClick={e => { setShowChildren(true); }}><i className="bi bi-plus"></i></span>

                                <div className="load-elipsis"><div></div><div></div><div></div><div></div></div>
                            </h3>
                        </div>
                    </>}
            </div>
        </div>
    </>
}

export default ExpenseProject;