import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { set_sync_status } from "../../actions/Sync";
import { getSlotName } from "../../libs/SlotUtils";
import AddSlot from "../Slots/AddSlot";
import ExpenseMask from "../Slots/ExpenseMask";

import wrappedImage from "../../assets/wrapped.png"
import { getCalendarWeekForDate, getFriday, getMonday } from "../../libs/CalendarWeek";
import SyncConstants from "../../constants/SyncConstants";

const Dashboard = (props) => {

	// React Hooks
	const Slots = useSelector((state) => state.Slot);
	const UserSettings = useSelector((state) => state.User);
	const Team = useSelector((state) => state.Team);
	const [showMoreReleasenotes, setShowMoreReleasenotes] = useState(false);
	const dispatch = useDispatch();

	// Component Variables
	const SlotName1 = getSlotName(new Date(), UserSettings.userName, "1");
	const SlotName2 = getSlotName(new Date(), UserSettings.userName, "2");

	const [slotifyWrappedName, setSlotifyWrappedName] = useState("Das Slotify-Team");

	// States
	const [CurrentUserObject, setCurrentUserObject] = useState("");
	const [Slot1Object, setSlot1Object] = useState(undefined);
	const [Slot2Object, setSlot2Object] = useState(undefined);
	const [totalSlotCount, setTotalSlotCount] = useState(0);
	const [totalTeamCount, setTotalTeamCount] = useState(0);
	const [expenseEntryMask, setExpenseEntryMask] = useState(false);

 


	// Update often used variables to ensure readability.
	useEffect(() => {
		setCurrentUserObject(Team[UserSettings.userName]);
		setSlot1Object(Slots[SlotName1]);
		setSlot2Object(Slots[SlotName2]);
		setTotalSlotCount(Object.values(Slots).length);
		setTotalTeamCount(Object.values(Team).length);


	}, [Team[UserSettings.userName], Slots[SlotName1], Slots[SlotName2], Slots, Team]);

	// Effect: Ensure that everything is synced after the dashboard is initialized
	const init = useEffect(() => {
		dispatch(set_sync_status("asap_full"));
	}, []);



	return (
		<>
			<div className="container mt-5">
				{/* UserObject is found, lets greet the user */}
				{typeof CurrentUserObject != "undefined" && <h1>Hi, {CurrentUserObject.realName}!</h1>}
				{/* UserObject not found, show Sync-Message */}
				{typeof CurrentUserObject == "undefined" && <h1>Hi! (Wir synchronisieren gerade noch etwas)</h1>}
								
				<div className="row mt-3 pt-4">
					<div className="col-4">
						<h2>Slot-Kalender</h2>
						<h4 className="mb-2 mb-4 mt-2 fw-light">Den Arbeitstag planen</h4>
						<Link to="/calendar" className="">
							Zum Slot-Kalender
						</Link>
					</div>

					<div className="col-4">
						<h2>Urlaubskalender</h2>
						<h4 className="mb-2 mb-4 mt-2 fw-light">Den Urlaub planen</h4>
						<a target="_BLANK" href="https://unternehmenonlinedo.sharepoint.com/UO-Intranet/Lists/Urlaubskalender/calendar.aspx" className="">
							Zum Urlaubskalender
						</a>
					</div>

					<div className="col-4">
						<h2>Arbeitsumgebung</h2>
						<h4 className="mb-2 mb-4 mt-2 fw-light">Software und Geräte einrichten</h4>
						<a href="https://wiki.shared-01.uo-cloud.de/books/arbeiten-bei-uo/page/vpn-zugang-uo" target="_BLANK" className="">
							VPN-Verbindung daheim einrichten
						</a>
						<br />
						<a href="https://wiki.shared-01.uo-cloud.de/books/arbeiten-bei-uo/page/lizenzen-bei-unternehmenonline" target="_BLANK" className="">
							Software-Lizenzen von UO
						</a>
						<br />
						<a href="https://wiki.shared-01.uo-cloud.de/books/arbeiten-bei-uo/page/dokumente-oder-passworter-sicher-versenden" target="_BLANK" className="">
							Dokumente und Kennwörter sicher versenden
						</a>
						<br />
						<a href="https://wiki.shared-01.uo-cloud.de/books/arbeiten-bei-uo/chapter/weclapp" target="_BLANK" className="">
							Informationen zu WeClapp (ERP-System)
						</a>
					</div>

					<div className="row mt-5">

			

						<div className="col-8">
							<h2>Neuigkeiten bei Slotify</h2>

							<h4 className="mb-2 mb-4 mt-2 fw-light">Letzte Änderungen im Webclient  (Slotify {SyncConstants["slotifyVersion"]})</h4>


							<ul className="bullet">

								<li>
									<b>Anpassungen am Autofill.</b> Anpassung des Autofill-Verhaltens (Workaround für Chrome-basierte Browser).
								</li>

								<li>
									<b>Ursprüngliches Suchverhalten wiederhergestellt.</b> Das ursprüngliche Verhalten der Suche wurde wiederhergestellt.
								</li>

								<li>
									<b>Aufwände bearbeiten.</b> Aufwände können nun auch direkt in der Slotansicht bearbeitet werden.
								</li>

								<li>
									<b>Aufwände verschieben.</b> Aufwände können nun in ein anderes Projekt verschoben werden.
								</li>

								<li>
									<b>Projekte archivieren.</b> Projekte können nun archiviert werden. Dies setzt Client-Version 16 oder neuer voraus.
								</li>

								<li>
									<b>UX verbessert.</b> Die klickbare Fläche zum Einfügen von Slots wurde vergrößert, um das Hinzufügen von Slots zu erleichtern (Gruß an Yassin!).
								</li>

								<li>
									<b>Project Breakdown.</b> Ab Januar 2025 im NG-Featuretest: Project Breakdown. Eine nach Monaten separierte Statistik bietet zusätzliche Einblicke in die gebuchten Aufwände.
								</li>

								<li>
									<b>Verbesserungen in der Aufwandsliste.</b> Der Stand eines Projektes wird nun prozentual ausgewertet und farbig markiert, um den Fortschritt besser zu visualisieren.
								</li>
								
								<li>
									<b>Slotify Wrapped 2024.</b> Einige allgemeine Statistiken eingefügt, um das Jahr 2024 Revue passieren zu lassen.
								</li>

								<li>
									<b>Überarbeitete Slot-Ansicht.</b> Das Slot-Detailfenster wurde überarbeitet, da einige (nicht verwendete) Funktionen entfernt wurden.
								</li>

								<li>
									<b>Verbessertes Gravatar-Handling.</b> Die Gravatar-Komponente wurde geforkt und durch UO weitergepflegt.
								</li>

								<li>
									<b>Verbesserte Synchronisierung.</b> Die Methode zur Synchronisierung im Hintergrund ist nun etwas sparsamer mit den Daten.
								</li>

								<li>
									<b>Java-Team ergänzt.</b> Das Java-Team (AXA) ist nun auf Slotify vertreten (Teamseite u. Aufwandsmodul).
								</li>

								<li>
									<b>Update-Hinweis.</b> Viele Fehler in der Vergangenheit gingen auf alte Slotify-Versionen auf den Clients zurück. Diesen wird nun ein Hinweis angezeigt, sofern es ein (nennenswertes) Update gibt.
								</li>

								<li>
									<b>Verbesserung der Suche.</b> Suchergebnisse im Aufwandsmodul werden nun nach Tippen des dritten Buchstabens automatisch ausgeklappt.
								</li>

								<li>
									<b>Bugfix.</b> Ein Spinning-Button verhindert nun, dass ungeduldige Nutzer:innen durch mehrfaches Klicken mehrere Aufwandseinträge anlegen.
								</li>

								{!showMoreReleasenotes && (
									<li
										className="cursor-pointer"
										onClick={() => {
											setShowMoreReleasenotes(true);
										}}>

										<b className="text-primary">Ältere Release-Notes anzeigen</b>
									</li>
								)}

								{showMoreReleasenotes && (
									<>
	

										<li>
											<b>Aufwände bearbeiten.</b> Bereits gebuchte Aufwände können nun bearbeitet werden - das Computerzeitalter fasziniert immer wieder aufs neue!
										</li>

										<li>
											<b>Ladeanimation.</b> Es gibt nun eine Ladeanimation im Aufwandsmodul.
										</li>

										<li>
											<b>»Als Root-Knoten anzeigen«.</b> Unterprojekte im Aufwandsmodul können nun als oberster Knoten verwendet werden. Dies sorgt für mehr Ordnung.
										</li>

										<li>
											<b>Tätigkeiten.</b> Zu jedem Aufwand muss nun eine Tätigkeit erfasst werden. Diese ist für die Abrechnung relevant und wird anhand eures Profils automatisch vorausgewählt, kann aber jederzeit im Dropdown geändert werden.
										</li>

										<li>
											<b>Slotdatum in Aufwand übernehmen.</b> Beim Buchen eines Aufwandes über den Slotkalender wird das Slotdatum statt dem heutigen Datum übernommen.
										</li>

										<li>
											<b>Datum setzen.</b> Beim Erstellen von Aufwandseinträgen kann nun das Datum gesetzt werden.
										</li>

										<li>
											<b>Tabelle expandieren.</b> Die Aufwandstabelle kann nun über einen Button bis zum Bildschirmrand erweitert werden. Dadurch bleiben die meisten Zellen bei gängigen Auflösungen einzeilig.
										</li>

										<li>
											<b>Nach Tagen gruppieren.</b> Aufwandsbuchungen können nach Tagen gruppiert werden, um langen Listen mehr Struktur zu geben.
										</li>

										<li>
											<b>Projekte editieren.</b> Bestehende Projekte können jetzt editiert werden.
										</li>

										<li>
											<b>Buchungen ohne Slots.</b> Buchungen können nun auch ohne Slot-Bezug angelegt werden (Links im Dashboard).
										</li>

										<li>
											<b>Status-Indikatoren.</b> Sofern gepflegt, wird ein grafischer Indikator für den gesamten Projektfortschritt angezeigt. Dieser ermittelt sich aus der Summe aller Aufwände.
										</li>

										<li>
											<b>Aufwandsbuchungen.</b> Die Aufwandsbuchungen sind endlich da! Ab September 2024 testen wir die Möglichkeit, Aufwände zu Projekten zu buchen. Diese sind unter dem Stichwort NG-Funktionen (Next-Generation) testbar. Die Datenbasis ist jedoch bereits Abrechnungsgrundlage, daher Testeinträge bitte umgehend löschen!
										</li>

										<li>
											<b>Teil-Synchronisierung.</b> Slots werden jetzt nur noch für die aktuelle Kalenderwoche synchronisiert. Dadurch ist die Performance deutlich besser und die gesamte Historie (bis Feburuar 2023) ist verfügbar.
										</li>

										<li>
											<b>Vorbereitungen für das 2024-Update.</b> Slotiy läuft nun auf Basis von MySQL. Der Sync-Mechanismus wird in den nächsten Tagen und Stunden noch weiter angepasst.
										</li>
										<li>
											<b>Slot-Merge.</b> Wenn der zweite Slot an einem Tag den gleichen Namen wie der erste Slot hat, wird nur der erste Slot über die ganze Breite angezeigt.
										</li>
										<li>
											<b>Verbesserungen am Login.</b> Das Kennwort-Feld ist nun durchgehend sichtbar, wodurch im Browser gespeicherte Kennwörter direkt eingegeben oder ausgewählt werden können. Wenn der Browser die Daten direkt ausfüllt, wird man sogar komplett automatisch eingeloggt.
										</li>
										<li>
											<b>Aufräumarbeiten & Performance.</b> Slotify entwickelt sich ständig weiter - genau wie unsere tägliche Zusammenarbeit. Daher wurden einige Funktionen entfernt, die wir im Alltag nicht mehr nutzen.
										</li>

										<li>
											<b>Optische Anpassungen ans UO-CI.</b> Slotify folgt nun dem UO-CI etwas mehr.
										</li>
										<li>
											<b>Out-of-Office-Kriterien.</b> Krankheit ist nun ein Kriterium für die Out-of-Office-Berechnung.
										</li>
										<li>
											<b>Stealth-Mode entfernt.</b> Neue Funktionen im Client sind wichtig - noch wichtiger ist aber, sich auch von Altlasten zu trennen, wenn sie niemand nutzt. Der Stealth-Modus wurde aus der Toolbar entfernt. Wenn sich niemand beschwert, fliegt er in einer der zukünftigen Versionen von Slotify komplett raus.
										</li>
										<li>
											<b>Ehemalige Mitarbeiter:innen.</b> Standardmäßig werden inaktive Accounts (z. B. von ehemaligen Mitarbeiter:innen) nicht mehr im Slotkalender eingeblendet. Über die Toolbar lässt sich diese Option verändern.
										</li>
										<li>
											<b>WeClapp-Integration.</b> Die Benutzer:in kann beim Erstellen oder Bearbeiten eines Slots nun die Autocomplete-Funktion für WeClapp-Aufträge nutzen.
										</li>
										<li>
											<b>Abwesende Benutzer:innen muten.</b> Im Slotkalender werden Mitarbeiter:innen nahezu ausgeblendet, sofern sie die ganze Woche nicht im Büro sind.
										</li>
										<li>
											<b>Automatische Team-Vorwahl.</b> Beim Wechsel in den Slot-Kalender wird automatisch das Team der Benutzer:in vorausgewählt.
										</li>
										<li>
											<b>Sync-Intervall reduziert.</b> Das Intervall für die automatische Synchronisierung wurde reduziert - die Hintergrund-Synchronisierung geschieht jetzt nur noch alle 25 Sekunden (Gruß an Ralf T. und die Traffic-Rechnung von Knipp!).
										</li>
										<li>
											<b>Autocomplete für den Slot-Titel.</b> Um einheitlichere Slot-Titel zu erhalten, werden der Benutzer:in nun bereits bestehende Projekte beim Tippen vorgeschlagen.
										</li>
										<li>
											<b>»Mehr«-Button bei langen Beschreibungen.</b> Für lange Beschreibungstexte in der Slotansicht wird der Benutzer:in nun ein Button angezeigt, um den gesamten Text auszuklappen.
										</li>
										<li>
											<b>Löschen im Control-Mode.</b> Über den Control-Mode können nun Slots gelöscht werden. Drückt die Benutzer:in die STRG-Taste im Slotkalender, werden die Funktionen eingeblendet (Gruß an Ralf Mika!).
										</li>
										<li>
											<b>Control-Mode.</b> Das Drücken der STRG- bzw. Control-Taste im Slotkalender zeigt einige Aktionen als Shortcut an.
										</li>
										<li>
											<b>Logo-Klick.</b> Ein Klick auf das Logo führt nun zum Dashboard (Gruß an Till!).
										</li>
										<li>
											<b>iCal-Download.</b> Slots können nun als Termin heruntergeladen werden - inkl. Teilnehmer:innen und Slotzeiten!
										</li>
										<li>
											<b>Bugfix.</b> Trello-Slots werden nun auch korrekt gestyled (Gruß an Nils!).
										</li>
										<li>
											<b>Maintenance-Release.</b> Die externen Abhängigkeiten wurden aktualisiert.
										</li>
										<li>
											<b>Feature.</b> Der Stealth-Mode ermöglicht es, in Ausnahmefällen mit Kund:innen gemeinsam in die Slotplanung zu schauen. Dabei werden die Slotbezeichnungen anonymisiert und nur die Slots angezeigt, die dem Suchwort in der Suche entsprechen.
										</li>
										<li>
											<b>Feature.</b> WeClapp-Slots werden nun mit dem WeClapp-Logo versehen. Bitte immer - falls vorhanden - eine Auftrags-ID verknüpfen!
										</li>
										<li>
											<b>Maintenance-Release.</b> Abhängigkeiten aktualisiert.
										</li>
										<li>
											<b>Maintenance-Release.</b> Abhängigkeiten aktualisiert. React 18 Alpha (Das ist Leben am Limit!).
										</li>
										<li>
											<b>Feature.</b> Per Klick auf den Synchronisierungs-Button im Header wird nun der lokale Slot-Cache geleert.
										</li>
										<li>
											<b>Feature.</b> Status-Indikator im Header zeigt den Synchronisierungsstatus an.
										</li>
										<li>
											<b>Bugfix.</b> Wechsel der Kalenderwoche nun wieder möglich (Gruß an Yassin).
										</li>
										<li>
											<b>Feature.</b> Der Filter "Team" in der Kalenderansicht bleibt nun bestehen, auch wenn man ein anderes Modul aufgerufen hat.
										</li>
										<li>
											<b>Feature.</b> Slots können nun automatisch auf den nächsten freien Slot aufgeschoben werden. Aufgeschoben ist nicht aufgehoben!
										</li>
										<li>
											<b>Feature.</b> Die Arbeitsauslastung wurde erweitert und als Tooltip in die Kalenderansicht integriert.
										</li>
										<li>
											<b>Feature.</b> Die Team-Übersicht wurde überarbeitet und zeigt nun die Slot-Auslastung einzelner Personen an.
										</li>
										<li>
											<b>Cleanup.</b> Einige Funktionen wurden in React-Hooks ausgelagert.
										</li>
										<li>
											<b>Feature.</b> Der Tooltip wurde durch eine Modalbox ausgetauscht, damit mehr Informationen angezeigt werden können.
										</li>
										<li>
											<b>Performance.</b> Kleinere Anpassungen bzgl. der Performance (Gruß an Jan).
										</li>
										<li>
											<b>Feature.</b> Slots öffnen ihre Tooltip-Ansicht nun nicht mehr durch Hovern, sondern durch Klick (oder Rechtsklick).
										</li>
										<li>
											<b>Product Owner.</b> Zu einem Slot kann nun ein <i>Product Owner</i> angegeben werden, der für den Slot verantwortlich ist.
										</li>
										<li>
											<b>Maintenance-Release.</b> Die externen Abhängigkeiten wurden aktualisiert.
										</li>
										<li>
											<b>Bearbeiten.</b> Slots können nun bearbeitet werden - ein Grund zum Feiern!
										</li>
										<li>
											<b>Performance.</b> Die Performance beim Starten der Anwendung ist nun deutlich beschleunigt - auch ohne Lazy Loading! (Gruß an Jan für die Beschwerde bzgl. der Performance).
										</li>
										<li>
											<b>Feature.</b> Neuigkeiten auf dem Dashboard anzeigen (Gruß an Karsten!).
										</li>
										<li>
											<b>Feature.</b> Das Datum wird nun am Ende der Kalenderspalten angezeigt, was das Handling mit mehr Mitarbeitern vereinfacht (Gruß an Ralf Mika!).
										</li>
										<li>
											<b>Bugfix.</b> Oktober-Slots erhalten nun keine führenden Nullen im Monat der Slot-ID mehr.
										</li>
										<li>
											<b>Bugfix.</b> Kleiner Rechtschreibfehler auf dem Login-Bildschirm behoben (Gruß an Lukas!).
										</li>
										<li>
											<b>Feature.</b> Kalenderwoche im Slot-Kalender klickbar gemacht, um zur aktuellen KW zu springen (Gruß an Jan!).
										</li>
										<li>
											<b>Feature.</b> Farbanpassungen in der Kalender-Ansicht (kontrastreichere Farben, heutigen Tag hervorgehoben).
										</li>
									</>
								)}
							</ul>
						</div>

										
						<div className="col-4">
							<h2>Aktuelle Kalenderwoche</h2>
							<p className="">
							 	Wir befinden uns in Kalenderwoche:
							 </p>

								<p className="display-1 text-center text-secondary mt-4">
									{getCalendarWeekForDate(new Date())}
								</p>
						</div>
					</div>

				</div>
				<br /> <br />
			</div>
		</>
	);
};

export default Dashboard;
