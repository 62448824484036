import React, { useState } from "react";
import uoLogo from "../../assets/uo_logo.png";

import stylelist from "stylelist";
import IfCondition from "../Util/IfCondition";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update_user_profile } from "../../actions/User";
import { set_sync_status } from "../../actions/Sync";
import Gravatar from "../../libs/Gravatar";
import SharkIf from "../Util/IfCondition";


const Welcome = (props) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const UserSettings = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);

  const toggleShowMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  }

  const updateUserData = (event) => {
    dispatch(update_user_profile(event.target.id, event.target.value));

    if (event.target.id == "email" && validateEmail(event.target.value)) {
      setIsValidEmail(true);
    }

    if (event.target.id == "syncToken" && event.target.value.length > 5) {
      dispatch(set_sync_status("asap"));
      setIsValidToken(true);
    }
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  return (
    <>
      <div className="card border-0">
        <div className="card-body">
          <div className="row">

            {!UserSettings["firstLaunchDone"] &&
              <div className="d-flex justify-content-center">
                <div>

                  <h2 className="text-center mt-5 pt-5 mb-4">Slotify </h2>

                  <SharkIf animate={true} show={true} animation="fade" animationDelay={300}>
                    <div className="d-flex justify-content-center mt-2">
                      <Gravatar size={150} className={stylelist("avatar")} default="mp" email={UserSettings.email} />
                    </div>
                  </SharkIf>

                  <div className="form-group mb-3 mt-3">
                    <label htmlFor="email">E-Mailadresse</label>
                    <input autoComplete="off" value={UserSettings.email} onAnimationStart={updateUserData} onChange={updateUserData} type="text" className="form-control" name="email" id="email" />
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="token">Passwort</label>
                    <input autoComplete="off" value={UserSettings.synctoken} onAnimationStart={updateUserData} onChange={updateUserData} type="password" className="form-control" name="token" id="syncToken" />
                  </div>

                  <SharkIf animate={true} show={true} animation="fade" animationDelay={300}>
                    <>
                      <div className="d-flex justify-content-center mb-4">
                        <a onClick={() => { dispatch(set_sync_status("asap")); }} className="btn btn-primary mx-auto text-center">Login</a> <br />
                      </div>
                    </>
                  </SharkIf>

                  <div className="d-flex justify-content-center text-muted">
                    <small>Wir versuchen bereits, dich beim Tippen einzuloggen.</small>
                  </div>
                </div>

              </div>
            }

            <p className="text-primary text-center mt-3 cursor-pointer" onClick={() => { toggleShowMoreDetails() }}>Mehr anzeigen</p>

            <IfCondition show={showMoreDetails} animate={true} animation="fade" animationDelay={100}>
              <p className="text-center">Slotify ist die Slotplanungs-Software von UO. Falls du noch keinen Zugang besitzt, wende dich bitte an <b>kulach@unternehmen.online</b>.</p>
            </IfCondition>
          </div>
        </div>
 
      </div>
    </>
  );
};

export default Welcome;
